import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [isLogoVisible, setIsLogoVisible] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeSection, setActiveSection] = useState('location');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLogoVisible(false);
      setIsContentVisible(true);
    }, 3000); // delay before hiding the logo and showing the content

    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 3);
    }, 3000); // Change slide every 3 seconds

    const handleScroll = () => {
      const menuSection = document.querySelector('.menu-section');
      if (menuSection && window.scrollY >= menuSection.offsetTop - 10) {
        setActiveSection('menu');
      } else {
        setActiveSection('location');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(slideInterval);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const photos = Array.from({ length: 10 }, (_, i) => process.env.PUBLIC_URL + `/loc${i + 1}.png`);
  const slides = [
    process.env.PUBLIC_URL + '/home1.png',
    process.env.PUBLIC_URL + '/home2.png',
    process.env.PUBLIC_URL + '/home3.png'
  ];

  const scrollToSection = (section) => {
    const locationSection = document.querySelector('.location-section');
    const menuSection = document.querySelector('.menu-section');

    if (section === 'location' && locationSection) {
      window.scrollTo({
        top: locationSection.offsetTop,
        behavior: 'smooth',
      });
    } else if (section === 'menu' && menuSection) {
      window.scrollTo({
        top: menuSection.offsetTop,
        behavior: 'smooth',
      });
    }
    setIsMenuOpen(false); // Close the menu after clicking
  };

  return (
    <div className="App">
      {isLogoVisible && (
        <div className="full-screen-logo">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" className="full-screen-logo-image" />
        </div>
      )}
      {isContentVisible && (
        <>
          <div className="slideshow">
            {slides.map((slide, index) => (
              <img 
                key={index} 
                src={slide} 
                alt={`Slide ${index + 1}`} 
                className="slide" 
                style={{ transform: `translateX(-${currentSlide * 100}%)` }} 
              />
            ))}
            <div className="dots">
              {slides.map((_, index) => (
                <span 
                  key={index} 
                  className={`dot ${currentSlide === index ? 'active' : ''}`} 
                  onClick={() => setCurrentSlide(index)}
                ></span>
              ))}
            </div>
          </div>
          <header className="App-header">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" className="App-logo" />
          </header>
          <nav className="menu">
            <ul>
              <li className={activeSection === 'location' ? 'active' : ''} onClick={() => scrollToSection('location')}>Location</li>
              <li className={activeSection === 'menu' ? 'active' : ''} onClick={() => scrollToSection('menu')}>Menu</li>
            </ul>
          </nav>
          <div className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
          <div className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}>
            <ul>
              <li onClick={() => scrollToSection('location')}>Location</li>
              <li onClick={() => scrollToSection('menu')}>Menu</li>
            </ul>
          </div>
          <section className="location-section">
            <div className={`paragraph ${isContentVisible ? 'reveal' : ''}`}>
              Όλη η αλμύρα της θάλασσας και ο τραγανός<br />
              χαρακτήρας ενός νησιού<br />
              να χωρούν σε ένα βότανο..<br />
              How can all the saltiness of the sea<br />
              and the crisp temperament of an island<br />
              fit in a herb..
            </div>
            <div className="slider-container">
              <div className="slider">
                <button className="prev" onClick={() => document.querySelector('.slider').scrollBy({ left: -200, behavior: 'smooth' })}>&#10094;</button>
                {photos.map((photo, index) => (
                  <img key={index} src={photo} alt={`Photo ${index + 1}`} className="slider-photo" />
                ))}
                <button className="next" onClick={() => document.querySelector('.slider').scrollBy({ left: 200, behavior: 'smooth' })}>&#10095;</button>
              </div>
            </div>
          </section>
          <section className="menu-section">
            <div className="vertical-line"></div>
            <a href={process.env.PUBLIC_URL + '/menu.pdf'} target="_blank" rel="noopener noreferrer" className="view-menu-button">View Our Menu</a>
            <img src={process.env.PUBLIC_URL + '/menu1.png'} alt="Menu" className="menu-photo" />
          </section>
          <footer className="footer">
            <div className="horizontal-line"></div>
            <div className="contact-info">
              <p>Address: Triantaros, Tinos, Cyclades, Greece, 84200</p>
              <p>Phone No.: 2283 041010</p>
              <p>Email: kritamosmediterraneancusine@gmail.com</p>
            </div>
            <div className="social-menu">
              <a href="https://www.facebook.com/profile.php?id=61560461927755" target="_blank" rel="noopener noreferrer">
                <img src={process.env.PUBLIC_URL + '/facebook-icon.png'} alt="Facebook" className="social-icon" />
              </a>
              <a href="https://www.instagram.com/kritamosrestauranttinos/" target="_blank" rel="noopener noreferrer">
                <img src={process.env.PUBLIC_URL + '/instagram-icon.png'} alt="Instagram" className="social-icon" />
              </a>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}

export default App;
